import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useLocation } from 'react-router-dom';
import HomeService from '../../services/HomeService';

const Blog = () => {
    const [blog, setBlog] = useState({})
    const location = useLocation()
    const homeService = new HomeService()

    useEffect(()=>{
        homeService.getBlog(location?.state?.hash).then(res=>{
            if(res?.status){
                setBlog(res?.data?.data)
            }
        })
    }, [])

    return <>
    <Helmet>
        <meta 
            name="description" 
            content={
                blog?.category === "Branding" ? "Discover how Cloud Lift Solutions crafts powerful brand identities through strategic design, messaging, and rebranding services, ensuring your brand resonates and stands out." :
                blog?.category === "Marketing" ? "Discover how Cloud Lift Solutions elevates GCC businesses with tailored marketing strategies, including SEO, content marketing, social media, and event management.Stay ahead with our integrated approach." :
                blog?.category === "Technology" ? "Discover how Cloud Lift Solutions helps businesses leverage IoT technology for enhanced efficiency, cost savings, and improved decision-making. Explore real-world applications and benefits of IoT integration." :
                blog?.category === "Web Development" ? "explore the essentials of website development in 2024 with Cloud Lift Solutions.Learn about front-end, back-end, CMS, and mobile optimization, tailored for businesses in Kuwait and the MENA region" :
                "Explore Cloud Lift Solutions' blog for the latest insights on IT solutions, web and app development, branding, and digital marketing. Stay updated with expert advice and industry trends."
            }
        />
        <title>
        {blog?.category === "Branding" ? "Elevate Your Brand with Expert Branding Services" : 
        blog?.category === "Marketing" ? "Comprehensive Marketing Services for GCC Businesses" : 
        blog?.category === "Technology" ? "Transform Your Business with IoT Solutions" : 
        blog?.category === "Web Development" ? "Beginner’s Guide to Website Development in 2024" : 
        'Industry Insights & IT Solutions Blog'} | Cloud Lift Solutions
        </title>
    </Helmet>
    <section className="mil-dark-bg">
        <div className="mil-inner-banner">
        <div className="mi-invert-fix">
            <div className="mil-banner-content mil-up">
                <div className="mil-animation-frame">
                    <div className="mil-animation mil-position-4 mil-scale" data-value-1="6" data-value-2="1.4"></div>
                </div>
                <div className="container" style={{flexDirection: "column", alignItems: "baseline"}}>
                    <ul className="mil-breadcrumbs mil-light mil-mb-60">
                        <li><Link to='/'>Homepage</Link></li>
                        <li><Link to='/blogs'>Blogs</Link></li>
                        <li>Blog</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div className='container'>
        <div className="mil-blog-card mil-mb-60">
                    <div className="mil-cover-frame mil-up">
                        <img src={blog?.image} alt="cover" />
                    </div>
                    <div className="mil-post-descr">
                        <div className="mil-labels mil-up mil-mb-15">
                            <div className="mil-label mil-upper mil-accent">{blog?.category}</div>
                            <div className="mil-label mil-upper mil-muted">{blog?.date?.split("T")[0]}</div>
                        </div>
                        <h4 className="mil-up mil-mb-15 mil-muted">{blog?.title}</h4>
                        <p className="mil-up mil-mb-15 mil-muted" dangerouslySetInnerHTML={{ __html: blog?.description }}></p>
                    </div>
                </div>
    </div>
    </section>
    </>
}
export default Blog;