import { Carousel, Modal } from "react-bootstrap"
import '../style.scss'

const ViewModal = ({modal, setModal, item})=>{

    return(
        <Modal className="fade design-modal" show={modal} onHide={()=>{
            setModal()
            }}>
            <Carousel>
                {item?.web_design_attachments?.map((res=>{
                    return <Carousel.Item key={res?.id}>
                    <img
                        className="d-block w-100 h-100"
                        src={res?.url}
                        alt="First slide"
                    />
                    </Carousel.Item>
                }))}
            </Carousel>
        </Modal>)
}

export default ViewModal;