import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import HomeService from "../../services/HomeService"
import { Helmet } from "react-helmet"

const Services = () => {
    const [data, setData] = useState([])
    const homeService = new HomeService()

    useEffect(()=>{
        homeService.getServices().then(res=>{
            if(res?.status === 200){
                setData([...res?.data?.data])
            }
        })
    },[])

    return <>
    <Helmet>
        <meta name="description" content="Cloud Lift Solutions offers custom web and app development, branding, anddigital marketing services in Kuwait. We specialize in creating tailored technology solutions that meet the unique needs of companies and entrepreneurs." />
        <meta name="keywords" content="Custom Web and App Development Kuwait" />
        <title>Custom Web & App Development Services in Kuwait | Cloud Lift Solutions</title>
    </Helmet>
    <div className="mil-dark-bg">
    <div className="mil-inner-banner">
        <div className="mi-invert-fix">
            <div className="mil-banner-content mil-up">
                <div className="mil-animation-frame">
                    <div className="mil-animation mil-position-4 mil-scale" data-value-1="6" data-value-2="1.4" style={{translate: 'none', rotate: 'none', scale: 'none', transform: 'translate3d(0px, 0px, 0px) scale(2.337, 2.337)'}}>
                        <div className="mil-dodecahedron">
                            <div className="mil-pentagon">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                            <div className="mil-pentagon">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                            <div className="mil-pentagon">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                            <div className="mil-pentagon">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                            <div className="mil-pentagon">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                            <div className="mil-pentagon">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                            <div className="mil-pentagon">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                            <div className="mil-pentagon">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                            <div className="mil-pentagon">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                            <div className="mil-pentagon">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                            <div className="mil-pentagon">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                            <div className="mil-pentagon">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <ul className="mil-breadcrumbs mil-light mil-mb-60">
                        <li><Link to='/'>Homepage</Link></li>
                        <li>Services</li>
                    </ul>
                    <h1 className="mil-muted mil-mb-60">This is <span className="mil-thin">what</span><br /> we do <span className="mil-thin">best</span></h1>
                    <a href="#services" className="mil-link mil-accent icons">
                        <span>Our services</span>
                        <i className="fa fa-arrow-down icon-black"></i>
                    </a>
                </div>
            </div>
        </div>
    </div>

    <section id="services">
        <div className="mi-invert-fix">
            <div className="container mil-p-120-60">
                <div className="row">
                    <div className="col-lg-5">

                        <div className="mil-lines-place mil-light">
                        <svg width="250" viewBox="0 0 300 1404" fill="none" xmlns="http://www.w3.org/2000/svg" className="mil-lines">
                        <path fillRule="evenodd" clipRule="evenodd" d="M1 892L1 941H299V892C299 809.71 232.29 743 150 743C67.7096 743 1 809.71 1 892ZM0 942H300V892C300 809.157 232.843 742 150 742C67.1573 742 0 809.157 0 892L0 942Z" className="mil-move"></path>
                        <path fillRule="evenodd" clipRule="evenodd" d="M299 146V97L1 97V146C1 228.29 67.7096 295 150 295C232.29 295 299 228.29 299 146ZM300 96L0 96V146C0 228.843 67.1573 296 150 296C232.843 296 300 228.843 300 146V96Z" className="mil-move"></path>
                        <path fillRule="evenodd" clipRule="evenodd" d="M299 1H1V1403H299V1ZM0 0V1404H300V0H0Z"></path>
                        <path fillRule="evenodd" clipRule="evenodd" d="M150 -4.37115e-08L150 1404L149 1404L149 0L150 -4.37115e-08Z"></path>
                        <path fillRule="evenodd" clipRule="evenodd" d="M150 1324C232.29 1324 299 1257.29 299 1175C299 1092.71 232.29 1026 150 1026C67.7096 1026 1 1092.71 1 1175C1 1257.29 67.7096 1324 150 1324ZM150 1325C232.843 1325 300 1257.84 300 1175C300 1092.16 232.843 1025 150 1025C67.1573 1025 0 1092.16 0 1175C0 1257.84 67.1573 1325 150 1325Z" className="mil-move"></path>
                        <path fillRule="evenodd" clipRule="evenodd" d="M300 1175H0V1174H300V1175Z" className="mil-move"></path>
                        <path fillRule="evenodd" clipRule="evenodd" d="M150 678C232.29 678 299 611.29 299 529C299 446.71 232.29 380 150 380C67.7096 380 1 446.71 1 529C1 611.29 67.7096 678 150 678ZM150 679C232.843 679 300 611.843 300 529C300 446.157 232.843 379 150 379C67.1573 379 0 446.157 0 529C0 611.843 67.1573 679 150 679Z" className="mil-move"></path>
                        <path fillRule="evenodd" clipRule="evenodd" d="M299 380H1V678H299V380ZM0 379V679H300V379H0Z" className="mil-move"></path>
                    </svg>
                        </div>

                    </div>
                    <div className="col-lg-7">
                        <div className="row">
                            <div className="col-md-6 col-lg-6">
                                <Link to="/branding" className="mil-service-card-lg mil-more mil-accent-cursor mil-offset">
                                    <h4 className="mil-muted mil-up mil-mb-30">Branding and <br />Identity Design</h4>
                                    <p className="mil-descr mil-light-soft mil-up mil-mb-30" dangerouslySetInnerHTML={{ __html: data[0]?.description }}></p>
                                    <ul className="mil-service-list mil-light mil-mb-30">
                                        {data[0]?.service_texts?.map((ser,index)=>{
                                            return <li key={index} className="mil-up">{ser?.text}</li>
                                        })}
                                    </ul>
                                    <div className="mil-link mil-accent icons mil-up">
                                        <span>Read more</span>
                                        <i className="fa fa-arrow-right icon-black"></i>
                                    </div>
                                </Link>
                            </div>
                            
                            <div className="col-md-6 col-lg-6">
                                <Link to="/marketing" className="mil-service-card-lg mil-more mil-accent-cursor mil-offset">
                                    <h4 className="mil-muted mil-up mil-mb-30">Advertising and <br />Marketing Campaigns</h4>
                                    <p className="mil-descr mil-light-soft mil-up mil-mb-30" dangerouslySetInnerHTML={{ __html: data[2]?.description }}></p>
                                    <ul className="mil-service-list mil-light mil-mb-30">
                                        {data[2]?.service_texts?.map((ser,index)=>{
                                            return <li key={index} className="mil-up">{ser?.text}</li>
                                        })}
                                    </ul>
                                    <div className="mil-link mil-accent icons mil-up">
                                        <span>Read more</span>
                                        <i className="fa fa-arrow-right icon-black"></i>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-md-6 col-lg-6">
                                <Link to="/website-design" className="mil-service-card-lg mil-more mil-accent-cursor">
                                    <h4 className="mil-muted mil-up mil-mb-30">Website & Application<br /> Development </h4>
                                    <p className="mil-descr mil-light-soft mil-up mil-mb-30" dangerouslySetInnerHTML={{ __html: data[1]?.description }}></p>
                                    <ul className="mil-service-list mil-light mil-mb-30">
                                        {data[1]?.service_texts?.map((ser,index)=>{
                                            return <li key={index} className="mil-up">{ser?.text}</li>
                                        })}
                                    </ul>
                                    <div className="mil-link mil-accent icons mil-up">
                                        <span>Read more</span>
                                        <i className="fa fa-arrow-right icon-black"></i>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section className="mil-soft-bg">
                    <div className="container mil-p-120-120">
                        <div className="row">
                            <div className="col-lg-10">

                                <span className="mil-suptitle mil-suptitle-right mil-suptitle-dark mil-up">Looking to make your mark? We'll help you turn <br /> your project into a success story.</span>

                            </div>
                        </div>
                        <div className="mil-center">
                            <h2 className="mil-up mil-mb-60">Let’s make an <span className="mil-thin">impact</span><br /> together. Ready <span className="mil-thin">when you are</span></h2>
                            <div className="mil-up">
                                <Link to="/contact-us" className="mil-button icons">
                                    <span>Contact us</span>
                                    <i className="fa fa-arrow-right icon-black"></i>
                                </Link>
                            </div>
                        </div>
                    </div>
                </section>
    
    </div>
    </>
}
export default Services