
import { API_BASE_URL_ENV } from '../common/common';
import http from './HttpService'
import axios from "axios";

const apiUploadEndpoint = API_BASE_URL_ENV() + "/files/uploadFile";

export default class BaseService {
  apiEndpoint;

  constructor(apiEndPoint) {
    this.apiEndpoint = apiEndPoint;
  }

  getList() {
    return http.get(this.apiEndpoint);
  }

  create(data) {
    return http.post(this.apiEndpoint, data);
  }

  postUpload(file) {
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";
    const formData = new FormData();
    formData.append("img", file);
    return http.post(apiUploadEndpoint, formData);
  }
}
