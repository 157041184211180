import 'bootstrap/dist/css/bootstrap.min.css';
import './common/css/style.css';
import './common/scss/style.scss';
import Home from './pages/Home';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Navbar from './layouts/Navbar';
import Footer from './layouts/Footer';
import Fixed from './layouts/Fixed';
import Services from './pages/Services';
import Error404 from './layouts/Error404';
import Branding from './pages/Branding';
import ContactUs from './pages/ContactUs';
import Carrers from './pages/Carrers';
import WebsiteDesign from './pages/WebsiteDesign';
import Blogs from './pages/Blogs';
import Blog from './pages/Blog';
import Marketing from './pages/Marketing';
import Preloader from './layouts/Preloader';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Clients from './pages/Clients';
import { Helmet } from 'react-helmet';
import { useEffect, useState } from 'react';
import HomeService from './services/HomeService';
import './App.css'
import ScrollToTop from './layouts/ScrollToTop';

function App() {
  const [data, setData] = useState({
    snap_chat_pixel: "",
    meta_pixel: "",
    google_analytics: ""
  })
  const homeService = new HomeService()

  useEffect(()=>{
    homeService.getMeta().then(res=>{
      if(res?.status === 200){
        setData(res?.data?.data)
      }
    })
  }, [])

  return (
    <>
    <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-WFFP7W5P" height="0" width="0" style={{display:"none",visibility:"hidden"}}></iframe>
    <ToastContainer />
    <Helmet> 
      {!!data?.snap_chat_pixel && <script>
        {`
        ${data?.snap_chat_pixel}
        `}
      </script>}
      {!!data?.meta_pixel && <script>
        {`
        ${data?.meta_pixel}
        `}
      </script>}
      {!!data?.google_analytics && <script>
        {`
        ${data?.google_analytics}
        `}
      </script>}
    </Helmet>
    
    <BrowserRouter>
    <Navbar />
    <ScrollToTop />
    <div className="mil-wrapper" id='top'>
        {/* preloader */}
        <Preloader />
         {/* preloader end  */}

      
      <div className="mil-content">
          <div id="swupMainm" className="mil-main-transitionn">
            <Routes>
                <Route path='/' element={<Home />} />
                <Route path='/services' element={<Services />} />
                <Route path='/branding' element={<Branding />} />
                <Route path='/contact-us' element={<ContactUs />} />
                <Route path='/carrers' element={<Carrers />} />
                <Route path='/blogs' element={<Blogs />} />
                <Route path='/blog/:slash' element={<Blog />} />
                <Route path='/marketing' element={<Marketing />} />
                <Route path='/website-design' element={<WebsiteDesign />} />
                <Route path='/clients' element={<Clients />} />
                <Route path='*' element={<Error404 />} />
            </Routes>
            <Fixed />
          </div>
      </div> 
      </div>
      <Footer /> 
      </BrowserRouter>
    </>);
}

export default App;
