import { useState } from 'react';
import { Link } from 'react-router-dom';
import BaseService from '../../services/BaseService';
import CareersService from '../../services/CareersService';
import './style.scss'
import { toast } from "react-toastify";

const Carrers = () => {
    const [formData, setFormData] = useState({
        name: "",
        phone: "",
        email: "",
        cv: ""
    })
    const careersService = new CareersService()

    const uploadCV = (e)=>{
        let filesAll = e.target.files;
        const filesData = Object.values(filesAll);
        
        if(filesAll?.length){
            new BaseService().postUpload(filesData[0]).then((res) => {
                if (res?.data?.status) {
                    setFormData({ 
                      ...formData, 
                      cv: res.data.url
                    });
                    let t = document.getElementById('noFile')
                    t.textContent = filesAll[0].name
                }
            });
        }
    }

    const handleInput = (e) => {
        setFormData({...formData, [e.target.name]: e.target.value})
    }

    const sendCarrer = () => {
        let data = {
            full_name: formData?.name,
            phone: formData?.phone,
            email: formData?.email,
            cv: formData?.cv,
        }

        careersService?.create(data).then(res=>{
            if(res?.status === 201){
                toast.success("Career Send Successflly.")
                setFormData({
                    name: "",
                    phone: "",
                    email: "",
                    cv: "",
                })
                let t = document.getElementById('noFile')
                t.textContent = "No file chosen..."
            }
        })
    }

    return <section className="mil-dark-bg">
        <div className="mil-inner-banner">
        <div className="mi-invert-fix">
            <div className="mil-banner-content mil-up">
            <div className="mil-animation-frame">
                    <div className="mil-animation mil-position-4 mil-scale" data-value-1="6" data-value-2="1.4" style={{translate: 'none', rotate: 'none', scale: 'none', transform: 'translate3d(0px, 0px, 0px) scale(2.337, 2.337)'}}>
                        <div className="mil-dodecahedron">
                            <div className="mil-pentagon">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                            <div className="mil-pentagon">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                            <div className="mil-pentagon">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                            <div className="mil-pentagon">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                            <div className="mil-pentagon">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                            <div className="mil-pentagon">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                            <div className="mil-pentagon">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                            <div className="mil-pentagon">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                            <div className="mil-pentagon">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                            <div className="mil-pentagon">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                            <div className="mil-pentagon">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                            <div className="mil-pentagon">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container" style={{flexDirection: "column", alignItems: "baseline"}}>
                    <ul className="mil-breadcrumbs mil-light mil-mb-60">
                        <li><Link to='/'>Homepage</Link></li>
                        <li>Carrers</li>
                    </ul>
                    <h1 className="mil-muted mil-mb-60">Carrers</h1>
                </div>
            </div>
        </div>
    </div>
        <div className="carrers">
            <div className='form'>
                <div className='container d-block'>
                    <div className="row w-100 mt-5">
                        <div className="col-md-6">
                            <label>Full Name</label>
                            <input type='text' required value={formData.name} name='name' onChange={(e)=> handleInput(e)} />
                        </div>
                        <div className="col-md-6">
                            <label>Phone</label>
                            <input type='text' required value={formData.phone} name='phone' onChange={(e)=> handleInput(e)} />
                        </div>
                        <div className="col-md-6">
                            <label>Email</label>
                            <input type='email' required value={formData.email} name='email' onChange={(e)=> handleInput(e)} />
                        </div>
                        <div className="col-md-6">
                            <label>CV</label>
                            <div className="file-upload">
                                <div className="file-select">
                                    <div className="file-select-button" id="fileName">Choose File</div>
                                    <div className="file-select-name" id="noFile">No file chosen...</div> 
                                    <input type="file" name="chooseFile" id="chooseFile" onChange={(e)=> uploadCV(e)} />
                                </div>
                            </div>
                        </div>
                        <div>
                            <button className="icons mil-button mt-4" onClick={sendCarrer} style={{height: "60px"}}>
                                <span style={{fontSize: "15px"}}>Send</span>
                                <i className="fa fa-arrow-right icon-black" ></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
}
export default Carrers;