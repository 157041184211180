import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import HomeService from '../../services/HomeService';
import { Helmet } from 'react-helmet';

const Blogs = () => {
    const [data, setData] = useState([])
    const homeService = new HomeService()

    useEffect(()=>{
        homeService.getBlogs().then(res=>{
            if(res?.status === 200){
                setData([...res?.data?.data?.data])
            }
        })
    },[])

    return <>
    <Helmet>
        <meta name="description" content="Explore Cloud Lift Solutions' blog for the latest insights on IT solutions, web and app development, branding, and digital marketing. Stay updated with expert advice and industry trends." />
        <title>Industry Insights & IT Solutions Blog | Cloud Lift Solutions</title>
    </Helmet>
    
    <section className="mil-dark-bg">
        <div className="mil-inner-banner">
        <div className="mi-invert-fix">
            <div className="mil-banner-content mil-up">
            <div className="mil-animation-frame">
                    <div className="mil-animation mil-position-4 mil-scale" data-value-1="6" data-value-2="1.4" style={{translate: 'none', rotate: 'none', scale: 'none', transform: 'translate3d(0px, 0px, 0px) scale(2.337, 2.337)'}}>
                        <div className="mil-dodecahedron">
                            <div className="mil-pentagon">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                            <div className="mil-pentagon">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                            <div className="mil-pentagon">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                            <div className="mil-pentagon">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                            <div className="mil-pentagon">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                            <div className="mil-pentagon">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                            <div className="mil-pentagon">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                            <div className="mil-pentagon">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                            <div className="mil-pentagon">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                            <div className="mil-pentagon">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                            <div className="mil-pentagon">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                            <div className="mil-pentagon">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container" style={{flexDirection: "column", alignItems: "baseline"}}>
                    <ul className="mil-breadcrumbs mil-light mil-mb-60">
                        <li><Link to='/'>Homepage</Link></li>
                        <li>Blogs</li>
                    </ul>
                    <h1 className="mil-muted mil-mb-60">Blogs</h1>
                </div>
            </div>
        </div>
    </div>
    <div className='container'>
    <div className="row">
        {data?.map(blog=>{
            return <div key={blog?.id} className="col-lg-4 mb-3">
            <Link to={`/blog/${blog.hash}`} state={blog} className="mil-blog-card mil-mb-60">
                <div className="mil-cover-frame mil-up">
                    <img src={blog?.image} alt="cover" />
                </div>
                <div className="mil-post-descr">
                    <div className="mil-labels mil-up mil-mb-15">
                        <div className="mil-label mil-upper mil-accent">{blog?.category}</div>
                        <div className="mil-label mil-upper mil-muted">{blog?.date?.split("T")[0]}</div>
                    </div>
                    <h4 className="mil-up mil-mb-15 mil-muted">{blog?.title}</h4>
                    <p className="mil-post-text mil-up mil-mb-15 mil-muted" dangerouslySetInnerHTML={{ __html: blog?.description }}></p>
                    <div className="mil-link icons mil-up">
                        <span className='mil-accent'>
                            Read more
                            <i className="fa fa-arrow-right mil-accent icon-white" ></i>
                        </span>
                    </div>
                </div>
            </Link>
        </div>
        })}
        </div>
    </div>
    </section>
    </>
}
export default Blogs;