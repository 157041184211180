import { useEffect, useState } from 'react';
import Slider from 'react-slick';
import HomeService from '../../../services/HomeService';

const OurPartners = () =>{
    const [data, setData] = useState([])
    const homeService = new HomeService()

    useEffect(()=>{
        homeService.getPartners().then(res=>{
            if(res?.status === 200){
                setData([...res?.data?.data])
            }
        })
    },[])
    const settings = {
        dots: false,
        arrows: false,
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 7000,
        // cssEase: "linear",
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 5,
                slidesToScroll: 1,
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
              }
            }
          ]
      
      };
    return <div >
        
    <div className="container mil-p-60">
        <div className="row align-items-center mil-mb-30">
            <div className="col-lg-6 mil-mb-60">
                <h3 className="mil-up">Our Partners:</h3>
            </div>
        </div>
        <div className="slider-container">
        <Slider {...settings}>
        {data?.map((imgs, index)=>{
                        return <img 
                            key={index} 
                            src={imgs.image} 
                            alt='client' 
                            style={{
                                width: '120px', height: '120px'
                            }}
                        />
                    })}
      </Slider>
      </div>
        {/* <div className="swiper-container mil-infinite-show mil-up">
            <div className="swiper-wrapper">
                <div className="swiper-slide">
                    <a href="" className="mil-partner-frame" style={{width: '60px'}}><img src={photo1} alt="logo" /></a>
                </div>
                <div className="swiper-slide">
                    <a href="" className="mil-partner-frame" style={{width: '100px'}}><img src={photo2} alt="logo" /></a>
                </div>
                <div className="swiper-slide">
                    <a href="" className="mil-partner-frame" style={{width: '60px'}}><img src={photo1} alt="logo" /></a>
                </div>
                <div className="swiper-slide">
                    <a href="" className="mil-partner-frame" style={{width: '100px'}}><img src={photo2} alt="logo" /></a>
                </div>
            </div>
        </div> */}
    </div>
</div>
}

export default OurPartners