import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/logo.png'
import SettingService from '../services/SettingService';

const Navbar = () =>{
    const [phoneNumber, setPhoneNumber] = useState('')
    const settingService = new SettingService()
    const [active, setActive] = useState(false)

    useEffect(()=>{
        settingService.getList().then(res=>{
            if(res?.status === 200){
                setPhoneNumber(res?.data?.data?.whatsapp)
            }
        })
    },[])

    const goToWhatsapp = () =>{
        let whatsappUrl = "https://wa.me/" + phoneNumber
        window.open(whatsappUrl);
    }

    const closeNavbar = () => {
        const nav = document.getElementById('navbar')
        const menuBtn = document.getElementById('menu-btn')
        const menuBtn2 = document.getElementById('menu-btn2')
        // const nav = document.getElementsByName('navbar')
        // const menuBtn = document.getElementById('menu-btn')

        setActive(false)
        nav.classList.remove('mil-active');
        menuBtn.classList.remove('mil-active');
        menuBtn2.classList.remove('mil-active');
    }

    return <>
    <div className={`mil-frame ${active ? "fixed" : ''}`}>
          <div className="mil-frame-top">
              <p className="d-inline-block mil-logo">
                <img 
                    src={logo} 
                    alt='logo' 
                    width='90px' 
                    style={{filter: 'drop-shadow(0 0 0.05rem #303D57)'}}
                />
              </p>
              <div className={`mil-menu-btn ${active ? "mil-active" : ''}`} onClick={()=> setActive(prev=> !prev)} id='menu-btn2'>
                  <span></span>
              </div>
          </div>
          <div className="mil-frame-bottom">
              <div className="mil-current-page">
              </div>
              <div className="mil-back-to-top" style={{display: 'flex', alignItems: 'center', gap: '18px'}}>
                  {phoneNumber && <i className='fab fa-whatsapp whatsapp' onClick={goToWhatsapp} style={{cursor: 'pointer'}}></i>}
                  <a href="#top" className="mil-link mil-dark mil-arrow-place">
                      <span>Back to top</span>
                  </a>
              </div>
          </div> 
          
      </div>
    <div className={`mil-menu-frame ${active ? "mil-active" : ''}`} id='navbar'>
    <div className="mil-frame-top">
        <p className="d-inline-block mil-logo">
        <img 
            src={logo} 
            alt='logo' 
            width='90px' 
            style={{filter: 'drop-shadow(0 0 0.05rem #303D57)'}}
        />
        </p>
        <div className="mil-menu-btn" id='menu-btn'>
            <span></span>
        </div>
    </div>
    <div className="container">
        <div className="mil-menu-content">
            <div className="row">
                <div className="col-xl-5">

                    <nav className="mil-main-menu" id="swupMenu">
                        <ul>
                            <li className="mil-has-children">
                                <Link to='/' onClick={()=> closeNavbar()}>Homepage</Link>
                            </li>
                            {/* <li className="mil-has-children">
                                <Link to='/'>About Us</Link>
                            </li> */}
                            <li className="mil-has-children">
                                <Link to='/services' onClick={()=> closeNavbar()}>Services</Link>
                            </li>
                            <li className="mil-has-children">
                                <Link to='/carrers' onClick={()=> closeNavbar()}>Careers</Link>
                            </li>
                            <li className="mil-has-children">
                                <Link to='/blogs' onClick={()=> closeNavbar()}>Blogs</Link>
                            </li>
                            <li className="mil-has-children">
                                <Link to='/contact-us' onClick={()=> closeNavbar()}>Contact Us</Link>
                            </li>
                        </ul>
                    </nav>

                </div>
                <div className="col-xl-7">
                    <div className="mil-menu-right-frame">
                        <div className="mil-animation-in">
                            <div className="mil-animation-frame">
                                <div className="mil-animation mil-position-1 mil-scale" data-value-1="2" data-value-2="2"></div>
                            </div>
                        </div>
                        <div className="mil-menu-right">
                            <div className="row">
                                <div className="col-lg-8 mil-mb-60">

                                    <h6 className="mil-muted mil-mb-30">Projects</h6>

                                    <ul className="mil-menu-list">
                                        <li><a href="project-1.html" className="mil-light-soft">Interior design studio</a></li>
                                        <li><a href="project-2.html" className="mil-light-soft">Home Security Camera</a></li>
                                        <li><a href="project-3.html" className="mil-light-soft">Kemia Honest Skincare</a></li>
                                        <li><a href="project-4.html" className="mil-light-soft">Cascade of Lava</a></li>
                                        <li><a href="project-5.html" className="mil-light-soft">Air Pro by Molekule</a></li>
                                        <li><a href="project-6.html" className="mil-light-soft">Tony's Chocolonely</a></li>
                                    </ul>

                                </div>
                                <div className="col-lg-4 mil-mb-60">

                                    <h6 className="mil-muted mil-mb-30">Useful links</h6>

                                    <ul className="mil-menu-list">
                                        <li><a href="#." className="mil-light-soft">Privacy Policy</a></li>
                                        <li><a href="#." className="mil-light-soft">Terms and conditions</a></li>
                                        <li><a href="#." className="mil-light-soft">Cookie Policy</a></li>
                                        <li><a href="#." className="mil-light-soft">Careers</a></li>
                                    </ul>

                                </div>
                            </div>
                            <div className="mil-divider mil-mb-60"></div>
                            <div className="row justify-content-between">

                                <div className="col-lg-4 mil-mb-60">

                                    <h6 className="mil-muted mil-mb-30">Canada</h6>

                                    <p className="mil-light-soft mil-up">71 South Los Carneros Road, California <span className="mil-no-wrap">+51 174 705 812</span></p>

                                </div>
                                <div className="col-lg-4 mil-mb-60">

                                    <h6 className="mil-muted mil-mb-30">Germany</h6>

                                    <p className="mil-light-soft">Leehove 40, 2678 MC De Lier, Netherlands <span className="mil-no-wrap">+31 174 705 811</span></p>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
</>
}
export default Navbar;