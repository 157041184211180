import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import logo from '../../../assets/logo.png'
import HomeService from '../../../services/HomeService'

const Services = () =>{
    const [data, setData] = useState([])
    const homeService = new HomeService()

    useEffect(()=>{
        homeService.getServices().then(res=>{
            if(res?.status === 200){
                setData([...res?.data?.data])
            }
        })
    },[])

    return <section className="mil-dark-bg">
    <div className="mi-invert-fix">
        <div className="mil-animation-frame">
            <div className="mil-animation mil-position-1 mil-scale" data-value-1="2.4" data-value-2="1.4" style={{translate: 'none', rotate: 'none', scale: 'none', transform: 'scale(1.6, 1.6)'}}>
                <div className="mil-dodecahedron"><div className="mil-pentagon"><div></div><div></div><div></div><div></div><div></div></div><div className="mil-pentagon"><div></div><div></div><div></div><div></div><div></div></div><div className="mil-pentagon"><div></div><div></div><div></div><div></div><div></div></div><div className="mil-pentagon"><div></div><div></div><div></div><div></div><div></div></div><div className="mil-pentagon"><div></div><div></div><div></div><div></div><div></div></div><div className="mil-pentagon"><div></div><div></div><div></div><div></div><div></div></div><div className="mil-pentagon"><div></div><div></div><div></div><div></div><div></div></div><div className="mil-pentagon"><div></div><div></div><div></div><div></div><div></div></div><div className="mil-pentagon"><div></div><div></div><div></div><div></div><div></div></div><div className="mil-pentagon"><div></div><div></div><div></div><div></div><div></div></div><div className="mil-pentagon"><div></div><div></div><div></div><div></div><div></div></div><div className="mil-pentagon"><div></div><div></div><div></div><div></div><div></div></div></div>
            </div>
            <div className="mil-animation mil-position-2 mil-scale" data-value-1="2" data-value-2="1" style={{translate: 'none', rotate: 'none', scale: 'none', transform: 'translate(0px, 0px)'}}>
                <div className="mil-dodecahedron"><div className="mil-pentagon"><div></div><div></div><div></div><div></div><div></div></div><div className="mil-pentagon"><div></div><div></div><div></div><div></div><div></div></div><div className="mil-pentagon"><div></div><div></div><div></div><div></div><div></div></div><div className="mil-pentagon"><div></div><div></div><div></div><div></div><div></div></div><div className="mil-pentagon"><div></div><div></div><div></div><div></div><div></div></div><div className="mil-pentagon"><div></div><div></div><div></div><div></div><div></div></div><div className="mil-pentagon"><div></div><div></div><div></div><div></div><div></div></div><div className="mil-pentagon"><div></div><div></div><div></div><div></div><div></div></div><div className="mil-pentagon"><div></div><div></div><div></div><div></div><div></div></div><div className="mil-pentagon"><div></div><div></div><div></div><div></div><div></div></div><div className="mil-pentagon"><div></div><div></div><div></div><div></div><div></div></div><div className="mil-pentagon"><div></div><div></div><div></div><div></div><div></div></div></div>
            </div>
        </div>
        <div className="container mil-p-120-0">

            <div className="mil-mb-120">
                <div className="row">
                    <div className="col-lg-10">

                        <span className="mil-suptitle mil-light-soft mil-suptitle-right mil-up">Professionals focused on helping your brand<br /> grow and move forward.</span>

                    </div>
                </div>

                <div className="mil-complex-text justify-content-center mil-up mil-mb-15">
                    <span className="mil-text-image">
                    <img 
                        src={logo} 
                        alt='logo'
                    />
                    </span>
                    <h2 className="mil-h1 mil-muted mil-center">Unique <span className="mil-thin">Ideas</span></h2>
                </div>
                <div className="mil-complex-text justify-content-center mil-up">
                    <h2 className="mil-h1 mil-muted mil-center">For Your <span className="mil-thin">Business.</span></h2>
                    <Link to="/services" className="icons mil-button">
                        <span>What we do</span>
                        <i className="fa fa-arrow-right icon-black" ></i>
                    </Link>
                </div>
            </div>

            <div className="row mil-services-grid m-0">
                <div className="col-md-6 col-lg-4 mil-services-grid-item p-0">

                    <Link to="/branding" className="mil-service-card-sm mil-up">
                        <h5 className="mil-muted mil-mb-30">Branding and <br />Identity Design</h5>
                        <p className="mil-light-soft mil-mb-30" dangerouslySetInnerHTML={{ __html: data[0]?.description }}></p>
                        <div className="mil-button mil-icon-button-sm icons-services">
                            <i className="fa fa-arrow-right icon-black" ></i>
                        </div>
                    </Link>

                </div>
                <div className="col-md-6 col-lg-4 mil-services-grid-item p-0">

                    <Link to="/website-design" className="mil-service-card-sm mil-up">
                        <h5 className="mil-muted mil-mb-30">Website & Application <br />Development</h5>
                        <p className="mil-light-soft mil-mb-30" dangerouslySetInnerHTML={{ __html: data[1]?.description }}></p>
                        <div className="mil-button mil-icon-button-sm icons-services">
                            <i className="fa fa-arrow-right icon-black" ></i>
                        </div>
                    </Link>

                </div>
                <div className="col-md-6 col-lg-4 mil-services-grid-item p-0">

                    <Link to="/marketing" className="mil-service-card-sm mil-up">
                        <h5 className="mil-muted mil-mb-30">Advertising and <br />Marketing Campaigns</h5>
                        <p className="mil-light-soft mil-mb-30" dangerouslySetInnerHTML={{ __html: data[2]?.description }}></p>
                        <div className="mil-button mil-icon-button-sm icons-services">
                            <i className="fa fa-arrow-right icon-black" ></i>
                        </div>
                    </Link>

                </div>
            </div>
        </div>
    </div>
</section>
}
export default Services