import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import Slider from 'react-slick';
import HomeService from '../../../services/HomeService';

const Clients = () =>{
    const [data, setData] = useState([])
    const homeService = new HomeService()

    useEffect(()=>{
        homeService.getClients().then(res=>{
            if(res?.status === 200){
                setData([...res?.data?.data])
            }
        })
    },[])

    const settings = {
        dots: false,
        arrows: false,
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 7000,
        // cssEase: "linear",
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 5,
                slidesToScroll: 1,
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
              }
            }
          ]
    };

    return <div>
    <div className="container mil-p-120-0 mil-p-0-90">

        <div className="swiper-container mil-infinite-show mil-up">
        <div className="row align-items-center mil-mb-30">
            <div className="col-lg-6 mil-mb-60">
                <h3 className="mil-up">Our Clients:</h3>
            </div>
        </div>
        {data?.length > 3 ? 
            <div className="slider-container">
                <Slider {...settings}>
                    {data?.map((imgs, index)=>{
                        return <img 
                            key={index} 
                            src={imgs.image}
                            height='120px' 
                            width='120px' 
                            alt='client' 
                        />
                    })}
                </Slider>
            </div> :  <div className='d-flex' style={{gap: '8px'}}>
                {data?.map((imgs, index)=>{
                        return <img 
                            key={index} 
                            src={imgs.image}
                            alt='client'
                            style={{
                                width: '120px', height: '120px'
                            }} 
                        />
                    })}
            </div> }
        </div>
        <div className="mil-up" style={{marginTop: '4rem', textAlign: 'center'}}>
            <Link to='/clients' className="mil-link icons">
                <span>
                    View All
                    <i className="fa fa-arrow-right icon-white" ></i>
                </span>
            </Link>
        </div>
    </div>
    
</div>
}
export default Clients