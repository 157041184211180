import React, { useEffect, useState } from "react"
import x from "../assets/icons/x-logo.png"
import SettingService from "../services/SettingService"

const Footer = () => {
    const [data, setData] = useState({})
    const [pdfs, setPdfs] = useState([])
    const settingService = new SettingService()

    useEffect(()=>{
        settingService.getList().then(res=>{
            if(res?.status === 200){
                setData({...res?.data?.data})
            }
        })
        settingService.getPdfsList().then(res=>{
            if(res?.status === 200){
                setPdfs([...res?.data?.data])
            }
        })
    },[])

    const handleLinkClick = (href) => {
        window.open(href, '_blank', 'noopener,noreferrer');
    };

    // const handleDownload = () => {
    //     // const link1 = document.createElement('a');
    //     // const link2 = document.createElement('a');

    //     // link1.href = pdfs[0]?.url;
    //     // link2.href = pdfs[2]?.url;

    //     // link1.target = '_blank'
    //     // link2.target = '_blank'

    //     // document.body.appendChild(link1, pdfs[0].id);
    //     // document.body.appendChild(link2, pdfs[2].id);
        
    //     // link1.click();
    //     // link2.click();
    //     pdfs.forEach((item, index) => {
    //         setTimeout(() => {
    //             handleLinkClick(item.url);
    //         }, index*1000); 
    //     })
    // };

    return <div className="mil-dark-bg">
    <div>
        <div className="container mil-p-60-30">
            <div className="row justify-content-between">
                <div className="col-md-4 col-lg-4 mil-mb-60">
                    <div className="mil-muted mil-logo mil-up mil-mb-30">Cloud Lift Solutions</div>
                    <div className="mil-vert-between">
                        <div className="mt-3">
                            <ul className="mil-social-icons mil-up">
                                {data?.facebook && <li>
                                    <button onClick={()=> handleLinkClick(data?.facebook)} className="social-icon"> 
                                        <i className="fab fa-facebook"></i>
                                    </button>
                                </li>}
                                {data?.twitter && <li>
                                    <button onClick={()=> handleLinkClick(data?.twitter)} className="social-icon"> <img src={x} alt="x" width='18px' style={{marginTop: '1px',borderRadius: '5px'}}></img></button></li>}
                                {data?.instagram && <li><button onClick={()=> handleLinkClick(data?.instagram)} className="social-icon"> <i className="fab fa-instagram"></i></button></li>}
                                {data?.snapchat && <li><button onClick={()=> handleLinkClick(data?.snapchat)} className="social-icon"> <i className="fab fa-snapchat"></i></button></li>}
                                {data?.linked_in && <li><button onClick={()=> handleLinkClick(data?.linked_in)} className="social-icon"> <i className="fab fa-linkedin"></i></button></li>}
                            </ul>
                            {/* {!!pdfs?.length && <button
                                onClick={handleDownload}
                                className="mil-muted mt-5"
                                style={{
                                    background: 'none',
                                    border: 'none',
                                    fontSize: '20px',
                                    cursor: 'pointer'
                                }}
                            >
                            Download our brochure
                            </button>} */}
                            {!!pdfs?.length && <div className="mt-5">
                                {pdfs?.map((pdf, index) => {
                                    return <React.Fragment key={index}>
                                        <a 
                                            className="mil-muted mb-2 d-block"
                                            style={{
                                                background: 'none',
                                                border: 'none',
                                                fontSize: '20px',
                                                cursor: 'pointer'
                                            }} 
                                            key={pdf?.id} 
                                            href={pdf?.url} 
                                            target="_blank" 
                                            rel="noreferrer"
                                        >
                                            <i className="fa fa-file-pdf me-2"></i>
                                            Download our {index === 0 ? "English" : "Arabic"} brochure
                                        </a>
                                    </React.Fragment>
                                })}
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div className="col-md-7 col-lg-6">
                    <div className="row justify-content-between">
                        <div className="col-md-6 col-lg-6 mb-3">
                            <h6 className="mil-muted mil-up mil-mb-15">Kuwait</h6>
                            <p className="mil-light-soft mil-up">{data?.address}</p>
                        </div>
                        <div className="col-md-6 col-lg-6">
                            <h6 className="mil-muted mil-up mil-mb-15">Email</h6>
                            <a className="mil-light-soft mil-up text-hover" href={`mailto:${data?.gmail}`}>{data?.gmail}</a>
                        </div>
                        <div className="col-md-6 col-lg-6">
                            <h6 className="mil-muted mil-up mil-mb-15">Phone</h6>
                            <a className="mil-light-soft mil-up text-hover" href={`tel:${data?.call_us}`}>{data?.call_us}</a>
                        </div>
                        <div className="col-md-6 col-lg-6">
                            <h6 className="mil-muted mil-up mil-mb-15">Working Hours</h6>
                            <p className="mil-light-soft mil-up">{data?.day_from}-{data?.day_to}  {data?.time_from} to {data?.time_to} </p>
                        </div>
                        </div>
                </div>
            </div>
        </div>
        <div className="container">
            <div style={{textAlign: 'center', paddingBottom: '12px'}}>
                <p className="mil-light-soft mil-up">© Copyright {new Date().getFullYear()} - Cloud Lift Solutions. All Rights Reserved</p>
            </div>
        </div>
    </div>
</div>
}
export default Footer;