import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import HomeService from '../../../services/HomeService';

const Publications = () =>{
    const [data, setData] = useState([])
    const homeService = new HomeService()

    useEffect(()=>{
        homeService.getBlogs().then(res=>{
            if(res?.status === 200){
                setData([...res?.data?.data?.data])
            }
        })
    },[])

    return <section className="mil-dark-bg">
    <div className="container mil-p-120-60">
        <div className="row align-items-center mil-mb-30">
            <div className="col-lg-6 mil-mb-30">
                <h3 className="mil-up mil-muted">Our Blog:</h3>
            </div>
            <div className="col-lg-6 mil-mb-30">
                <div className="mil-adaptive-right mil-up">
                    <Link to="/blogs" className="mil-link icons mil-muted">
                        <span>
                            View All
                            <i className="fa fa-arrow-right mil-muted icon-white" ></i>
                        </span>
                    </Link>
                </div>
            </div>
        </div>
        <div className="row">
            {data?.map((blog, index)=>{
                if(index < 2){
                    return <div key={index} className="col-lg-6">
                    <Link to={`/blog/${blog.hash}`} state={blog} className="mil-blog-card mil-mb-60">
                        <div className="mil-cover-frame mil-up">
                            <img src={blog?.image} alt="cover" />
                        </div>
                        <div className="mil-post-descr">
                            <div className="mil-labels mil-up mil-mb-15">
                                <div className="mil-label mil-upper mil-accent">{blog?.category}</div>
                                <div className="mil-label mil-upper mil-muted">{blog?.date?.split("T")[0]}</div>
                            </div>
                            <h4 className="mil-up mil-mb-15 mil-muted">{blog?.title}</h4>
                            <p className="mil-post-text mil-up mil-mb-30 mil-muted" dangerouslySetInnerHTML={{ __html: blog?.description }}></p>
                            <div className="mil-link icons mil-up">
                                <span className='mil-accent'>
                                    Read more
                                    <i className="fa fa-arrow-right mil-accent icon-white" ></i>
                                </span>
                            </div>
                        </div>
                    </Link>
                </div>
                }
            })}
        </div>
    </div>
</section>
}
export default Publications;