import { Link } from "react-router-dom"
import images from "../../assets/Market"
import "./style/style.scss"
import logo from '../../assets/logo-white.png'
import { useEffect, useState } from "react"
import MarketingService from "../../services/MarketingService"
import { Helmet } from "react-helmet"

const Marketing = () => {
    const [openVideo, setOpenVideo] = useState(false)
    const [formData, setFormData] = useState({
        description: "",
        services: [
            {title: "Design", description: ""},
            {title: "Marketing", description: ""},
            {title: "Developing", description: ""},
            {title: "Services Description", description: ""},
        ],
        video: { src: "", loading: false }
  })
    const marketingService = new MarketingService()

  useEffect(()=>{
    let bannerDescription = marketingService?.getBanners()
    let services = marketingService?.getServices()
    let video = marketingService?.getVideo()

    Promise.all([bannerDescription, services, video]).then(res=>{
      let data = {}
      if(res[0]?.status === 200){
        data['description'] = res[0]?.data?.data?.description;
      }

      if(res[1]?.status === 200){
        data['services'] = res[1]?.data?.data?.map(item=>{
          return {
            title: item?.title,
            description: item?.description,
          }
        })
      }

      if(res[2]?.status === 200){
        data['video'] =  { src: res[2]?.data?.data?.video, loading: false}
      }

      setFormData({...formData, ...data})
    }).catch(error => {
      console.log(error);
    });
  }, [])

    useEffect(()=> {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }, [])

    return <>
    <Helmet>
        <meta name="description" content="Cloud Lift Solutions offers expert digital marketing services in Kuwait, including SEO, content marketing, social media management,and media buying. We create seamless, effective strategies that drive growth and enhance brand visibility." />
        <meta name="keywords" content="Digital Marketing Services Kuwait" />
        <title>Digital Marketing Services in Kuwait | SEO | Cloud Lift Solutions</title>
    </Helmet>
     <section className="hero-area d-flex align-items-center">
        <Link to='/'>
			<img 
				src={logo} alt='logo'
				className="large-icon" width='90px' 
				// style={{filter: 'drop-shadow(0 0 0.05rem #303D57)'}}
			/>
		</Link>
        <div className="container custom-container">
            <div className="row">
                <div className="col-lg-6">
                    <div className="hero-content">
                        <h1 className="title">Marketing</h1>
                        <p className="text" dangerouslySetInnerHTML={{ __html: formData?.description }}></p>
                        <p className="get">Get Started</p>
                    </div>
                </div>
            </div>
        </div>
        <div className="hero-thumb animated wow fadeInRight" data-wow-duration="1500ms" data-wow-delay="10ms">
            <img src={images?.welcomeThumb} alt="" />
        </div>
        <div className="hero-shape">
            <img src={images?.shape5} alt="" />
        </div>
        <div className="hero-scroll">
            <p className="scroll">
                <span>S</span>
                <span>C</span>
                <span>R</span>
                <span>O</span>
                <span>L</span>
                <span>L</span>
            </p>
        </div>
    </section>

    {/* <!--====== ABOUT PART START ======--> */}
    <section className="about-area">
        <div className="container custom-container">
            <div className="row">
                <div className="col-lg-12">
                    <div className="section-title">
                        <h3 className="title">We Do Three Things</h3>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-xl-4 col-lg-6">
                    <div className="about-item mt-20">
                        <h4 className="title">{formData?.services[0]?.title}</h4>
                        <p dangerouslySetInnerHTML={{ __html: formData?.services[0]?.description }}></p>
                        <div className="icon">
                            <img src={images?.icon1} alt="icon" />
                        </div>
                    </div>
                </div>
                <div className="col-xl-4 col-lg-6">
                    <div className="about-item mt-20 active">
                        <h4 className="title">{formData?.services[1]?.title}</h4>
                        <p dangerouslySetInnerHTML={{ __html: formData?.services[1]?.description }}></p>
                        <div className="icon">
                            <img src={images?.icon2} alt="icon" />
                        </div>
                    </div>
                </div>
                <div className="col-xl-4 col-lg-6">
                    <div className="about-item item-3 mt-20">
                        <h4 className="title">{formData?.services[2]?.title} </h4>
                        <p dangerouslySetInnerHTML={{ __html: formData?.services[2]?.description }}></p>
                        <div className="icon">
                            <img src={images?.icon3} alt="icon" />
                        </div>
                    </div>
                </div>
                <div className="col-lg-12">
                    <div className="about-text">
                        <p className="text mb-5" dangerouslySetInnerHTML={{ __html: formData?.services[3]?.description }}></p>
                    </div>
                </div>
            </div>
        </div>
        <div className="about-shape-2">
            <img src={images?.shape5} alt="" />
        </div>
    </section>

    {/* <!--====== ABOUT PART ENDS ======--> */}
    {/* <!--====== PLAY PART START ======--> */}

    <div className="play-area">
        <div className="container-fluid p-0">
            <div className="row">
                <div className="col-lg-12">
                    {!openVideo ? <div className="play-thumb">
                        <div className="bg-image"></div>
                        <div className="play-btn" onClick={()=> setOpenVideo(true)}>
                            <p className="video-popup" >
                                <i className="fa fa-play"></i>
                            </p>
                        </div>
                    </div> :
                    <div className="play-thumb2">
                        <video controls className="w-100 h-100">
                            <source src={formData?.video?.src} type="video/webm" />
                            <source src={formData?.video?.src} type="video/mp4" />

                            </video>
                    </div>}
                </div>
            </div>
        </div>
    </div>

    {/* <!--====== PLAY PART ENDS ======--> */}
    </>
}
export default Marketing