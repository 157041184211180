import { useState } from 'react';
import { Link } from 'react-router-dom';
import ContactusService from '../../services/ContactusService';
import './style.scss'
import { toast } from "react-toastify";
import { Helmet } from 'react-helmet';

const ContactUs = () => {
    const [formData, setFormData] = useState({
        full_name: "",
        phone: "",
        email: "",
        message: ""
    })
    const contactusService = new ContactusService()

    const handleInput = (e) => {
        setFormData({...formData, [e.target.name]: e.target.value})
    }

    const sendContact = () => {
        let data = {
            full_name: formData?.full_name,
            phone: formData?.phone,
            email: formData?.email,
            message: formData?.message,
        }

        contactusService?.create(data).then(res=>{
            if(res?.status === 201){
                toast.success("Contact Us Send Successflly.")
                setFormData({
                    full_name: "",
                    phone: "",
                    email: "",
                    message: "",
                })
            }
        })
    }

    return <>
    <Helmet>
        <meta name="description" content="Get in touch with Cloud Lift Solutions for expert IT services, including web development, branding, and marketing in Kuwait. We're here to help your business thrive." />
        <title>Contact Us | Cloud Lift Solutions | IT Services in Kuwait</title>
    </Helmet>
    <section className="mil-dark-bg">
        <div className="mil-inner-banner">
        <div className="mi-invert-fix">
            <div className="mil-banner-content mil-up">
            <div className="mil-animation-frame">
                    <div className="mil-animation mil-position-4 mil-scale" data-value-1="6" data-value-2="1.4" style={{translate: 'none', rotate: 'none', scale: 'none', transform: 'translate3d(0px, 0px, 0px) scale(2.337, 2.337)'}}>
                        <div className="mil-dodecahedron">
                            <div className="mil-pentagon">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                            <div className="mil-pentagon">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                            <div className="mil-pentagon">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                            <div className="mil-pentagon">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                            <div className="mil-pentagon">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                            <div className="mil-pentagon">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                            <div className="mil-pentagon">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                            <div className="mil-pentagon">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                            <div className="mil-pentagon">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                            <div className="mil-pentagon">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                            <div className="mil-pentagon">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                            <div className="mil-pentagon">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container" style={{flexDirection: "column", alignItems: "baseline"}}>
                    <ul className="mil-breadcrumbs mil-light mil-mb-60">
                        <li><Link to='/'>Homepage</Link></li>
                        <li>Contact Us</li>
                    </ul>
                    <h1 className="mil-muted mil-mb-60">Contact Us</h1>
                </div>
            </div>
        </div>
    </div>
        <div className="contact-us">
            <div className='form'>
                <div className='container d-block'>
                    <div className="row w-100 mt-5">
                        <div className="col-md-6">
                            <label>Full Name</label>
                            <input type='text' name='full_name' value={formData?.full_name} onChange={(e)=> handleInput(e)} />
                        </div>
                        <div className="col-md-6">
                            <label>Phone</label>
                            <input type='text' name='phone' value={formData?.phone} onChange={(e)=> handleInput(e)} />
                        </div>
                        <div className="col-md-6">
                            <label>Email</label>
                            <input type='text' name='email' value={formData?.email} onChange={(e)=> handleInput(e)}/>
                        </div>
                        <div className="col-md-6"></div>
                        <div className="col-md-6">
                            <label>Message</label>
                            <textarea name='message' rows="4" value={formData?.message} onChange={(e)=> handleInput(e)} />
                        </div>
                        <div>
                            <button className="icons mil-button" onClick={sendContact} style={{height: "60px"}}>
                                <span style={{fontSize: "15px"}}>Send</span>
                                <i className="fa fa-arrow-right icon-black" ></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </>
}
export default ContactUs;