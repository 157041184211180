import "./style.scss"
import "./settings.scss"
import "./animate.scss"
import ipad from "../../assets/BrandPage/ipad.png"
import splash1 from "../../assets/BrandPage/splash-1.png"
import splash2 from "../../assets/BrandPage/splash-2.png"
import images from "../../assets/BrandPage"
import Slider from "react-slick";
import logo from '../../assets/logo.png'
import { Link } from "react-router-dom"
import { useEffect, useState } from "react"
import BrandingService from "../../services/BrandingService"
import { Helmet } from "react-helmet"

const Branding = () => {
	const brandingService = new BrandingService()
	const [formData, setFormData] = useState({
        wwd_description: "",
        services: [],
        banners: [],
        our_partners: []
  })

	useEffect(()=> {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }, [])

	useEffect(()=>{
		let banners = brandingService?.getBanners()
		let wwd = brandingService?.getWWD()
		let services = brandingService?.getServices()
		let images = brandingService?.getImages()
	
		Promise.all([banners, wwd, services, images]).then(res=>{
		  let data = {}
		  if(res[0]?.status === 200){
			const banners = res[0]?.data?.data?.map(info=>{
			  return {
				...info,
				src: info?.image
			  }
			});
			data['banners']= [...banners]
		  }
	
		  if(res[1]?.status === 200){
			data['wwd_description'] = res[1]?.data?.data?.description
		  }
	
		  if(res[2]?.status === 200){
			data['services'] = res[2]?.data?.data
		  }
	
		  if(res[3]?.status === 200){
			data['our_partners'] = res[3]?.data?.data
		  }
	
		  setFormData({...formData, ...data})
		}).catch(error => {
		  console.log(error);
		});
	}, [])

	const settings = {
		dots: false,
		fade: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		waitForAnimate: false,
		autoplay: true,
    	autoplaySpeed: 3000
	};

    return <>
	<Helmet>
        <meta name="description" content="Cloud Lift Solutions offers expert branding services in Kuwait, including custom brand identity design, logo design, and strategic brand messaging. Our tailored solutions help businesses create a strong, memorable brand presence." />
        <meta name="keywords" content="Branding Services Kuwait" />
        <title>Branding Services in Kuwait | Cloud Lift Solutions</title>
    </Helmet>
	<div className="branding">
		<Link to='/'>
			<img 
				src={logo} alt='large-logo'
				className="large-icon" width='90px' 
				style={{filter: 'drop-shadow(0 0 0.05rem #303D57)'}}
			/>
		</Link>
		<div className="slider-container">
			<Slider {...settings}>
				{formData?.banners?.map(banner=>{
					return <div key={banner?.id} style={{position: "relative"}}>
					<img src={banner?.image} alt={banner?.id} className='w-100' />
					<div className="titles">
						<p className="tp-caption sft sl-title" data-x="center" data-y="center" data-voffset="-90" data-speed="700" data-start="1700" data-easing="easeOutBack">
							{banner?.title}
						</p>
						<p className="tp-caption sfb second-title" data-x="center" data-y="center" data-voffset="40" data-speed="500" data-start="1900" data-easing="easeOutBack">
							{banner?.sub_title}
						</p>
					</div>
				</div>
				})}
				
			</Slider>
			<div className="scroll-down-button">
					<img src={images?.downButton} className="down-button" alt="image" />
					<i className="fa fa-angle-down"></i>
				</div>
		</div>
    <section className=" about-us padding-section">
		<div className="grid-row">
			<div className="grid-col-row clear">
				<div className="grid-col grid-col-6 col-sd-12">
					<div className="title">
						<span className="main-title">What we do</span>
					</div>
					<p className="mb-5" style={{width: '80%'}} dangerouslySetInnerHTML={{ __html: formData?.wwd_description }}></p>
					<Link 
						to='/contact-us'
						style={{
							border: '1px solid',
							padding: "8px 16px",
							borderRadius: '8px',
						}}
					>Get Started</Link>
				</div>
				<div className="grid-col grid-col-6 content-img col-sd-12">
					<img className="ipad" src={ipad} alt="image" />

					{/* Background Color */}
					<img id="splash-1" src={splash1} alt="image" />
					<img id="splash-2" src={splash2} alt="image" />
				</div>
			</div>
		</div>
	</section>
	<hr />
	<div classNameName="mt-5">
		<div className="grid-row clear">
			<div className="grid-col-row">
				{formData?.services?.map(service=>{
					return <div key={service?.id} className="item-example grid-col grid-col-4">
					<div className="rectangle text-center">
						<img src={service?.image} alt='services' style={{width: '100%', height: '100%'}} />
					</div>
					<h3>{service?.title}</h3>
					<div className="line"></div>
				</div>
				})}
			</div>
		</div>
	</div>
	<hr />

	<section id="portfolio" className="portfolio padding-section">
		<div className="grid-row">
			<div className="portfolio-filter clear">
				<div className="title d-flex justify-content-between">
					<div>
						<span className="main-title">PORTFOLIO</span>
						<span className="slash-icon">/</span>
						<br/>
						<span data-filter=".photo">PHOTGRAPHY</span>
						<span className="slash-icon">/</span>
						<span data-filter=".design">DESIGN</span>
						<span className="slash-icon">/</span> <br/>
						<span data-filter=".video">VIDEO</span>
						<span className="slash-icon">/</span>
						<span data-filter=".web">WEB</span>
						<span className="slash-icon">/</span>
						<span data-filter=".music">MUSIC</span>
					</div>
					<p className="all-iso-item active" data-filter=".item">ALL</p>
				</div>
				
			</div>
		</div>
		<div className="isotope">
			{formData?.our_partners?.map(part=>{
				return <div className="item design photo video web ">
				<div className="portfolio-hover">
					<div className="portfolio-info">
						<div className="portfolio-title">Future timeline</div>
						<div className="portfolio-divider"></div>
					</div>
				</div>
				<img src={part.image} alt="partners" />
			</div>
			})}
			{/* <div className="item design photo video web ">
				<div className="portfolio-hover">
					<div className="portfolio-info">
						<a href=""><div className="portfolio-title">Future timeline</div></a>
						<div className="portfolio-divider"></div>
						<div className="portfolio-description">Photography, Art</div>
					</div>
				</div>
				<img src={images.iso1} alt="image" />
			</div>
			<div className="item illust video ">
				<div className="portfolio-hover">
					<div className="portfolio-info">
						<a href=""><div className="portfolio-title">Sit Amet</div></a>
						<div className="portfolio-divider"></div>
						<div className="portfolio-description">Video, Art</div>
					</div>
				</div>
				<img src={images.iso3} alt="image" />
			</div>
			<div className="item design illust photo web ">
				<div className="portfolio-hover">
					<div className="portfolio-info">
						<a href=""><div className="portfolio-title">Sed Laoreete</div></a>
						<div className="portfolio-divider"></div>
						<div className="portfolio-description">Video, Photography</div>
					</div>
				</div>
				<img src={images.iso5} alt="image" />
			</div>
			<div className="item illust photo ">
				<div className="portfolio-hover">
					<div className="portfolio-info">
						<a href=""><div className="portfolio-title">Amet Interdum</div></a>
						<div className="portfolio-divider"></div>
						<div className="portfolio-description">Music, Art</div>
					</div>
				</div>
				<img src={images.iso8} alt="image" />
			</div>
			<div className="item design video web ">
				<div className="portfolio-hover">
					<div className="portfolio-info">
						<a href=""><div className="portfolio-title">Cras varius</div></a>
						<div className="portfolio-divider"></div>
						<div className="portfolio-description">Photography, Art</div>
					</div>
				</div>
				<img src={images.iso10} alt="image" />
			</div>
			<div className="item illust photo video ">
				<div className="portfolio-hover">
					<div className="portfolio-info">
						<a href=""><div className="portfolio-title">Maecenas Semper</div></a>
						<div className="portfolio-divider"></div>
						<div className="portfolio-description">Business, Art</div>
					</div>
				</div>
				<img src={images.iso2} alt="image" />
			</div>
			<div className="item illust video web music ">
				<div className="portfolio-hover">
					<div className="portfolio-info">
						<a href=""><div className="portfolio-title">Scelerisque Venen</div></a>
						<div className="portfolio-divider"></div>
						<div className="portfolio-description">Music, Art</div>
					</div>
				</div>
				<img src={images.iso6} alt="image" />
			</div>
			<div className="item design photo music ">
				<div className="portfolio-hover">
					<div className="portfolio-info">
						<a href=""><div className="portfolio-title">Libero Luctus</div></a>
						<div className="portfolio-divider"></div>
						<div className="portfolio-description">Music, Art</div>
					</div>
				</div>
				<img src={images.iso9} alt="image" />
			</div>
			<div className="item photo web music ">
				<div className="portfolio-hover">
					<div className="portfolio-info">
						<a href=""><div className="portfolio-title">Dlandit Cursus</div></a>
						<div className="portfolio-divider"></div>
						<div className="portfolio-description">Business, Art</div>
					</div>
				</div>
				<img src={images.iso4} alt="image" />
			</div>
			<div className="item photo video web music ">
				<div className="portfolio-hover">
					<div className="portfolio-info">
						<a href=""><div className="portfolio-title">Future timeline</div></a>
						<div className="portfolio-divider"></div>
						<div className="portfolio-description">Music, Photography</div>
					</div>
				</div>
				<img src={images.iso7} alt="image" />
			</div>
			<div className="item design video music ">
				<div className="portfolio-hover">
					<div className="portfolio-info">
						<a href=""><div className="portfolio-title">Sed Tempor</div></a>
						<div className="portfolio-divider"></div>
						<div className="portfolio-description">Business, Art</div>
					</div>
				</div>
				<img src={images.iso11} alt="image" />
			</div> */}
		</div>
	</section>

    </div>
	</>
}
export default Branding;