import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import logo from '../../../assets/logo.png'
import HomeService from "../../../services/HomeService"

const Header = () =>{
    const [title, setTitle] = useState("")
    const homeService = new HomeService()

    useEffect(()=>{
        homeService.getBanner().then(res=>{
            if(res?.status === 200){
                setTitle(res?.data?.data?.title)
            }
        })
    },[])

    return <section className="mil-banner mil-dark-bg">        
    <div className="mi-invert-fix">
        <img 
            src={logo} 
            alt='logo'
            className="large-icon"
            width='90px' 
            style={{filter: 'drop-shadow(0 0 0.05rem #303D57)'}}
        />
        <div className="mil-animation-frame">
            <div className="mil-animation mil-position-1 mil-scale" data-value-1="7" data-value-2="1.6" style={{translate: 'none', rotate: 'none', scale: 'none', transform: 'scale(1.6, 1.6)'}}>
                <div className="mil-dodecahedron">
                    <div className="mil-pentagon"><div></div><div></div><div></div><div></div><div></div></div>
                    <div className="mil-pentagon"><div></div><div></div><div></div><div></div><div></div></div>
                    <div className="mil-pentagon"><div></div><div></div><div></div><div></div><div></div></div>
                    <div className="mil-pentagon"><div></div><div></div><div></div><div></div><div></div></div>
                    <div className="mil-pentagon"><div></div><div></div><div></div><div></div><div></div></div>
                    <div className="mil-pentagon"><div></div><div></div><div></div><div></div><div></div></div>
                    <div className="mil-pentagon"><div></div><div></div><div></div><div></div><div></div></div>
                    <div className="mil-pentagon"><div></div><div></div><div></div><div></div><div></div></div>
                    <div className="mil-pentagon"><div></div><div></div><div></div><div></div><div></div></div>
                    <div className="mil-pentagon"><div></div><div></div><div></div><div></div><div></div></div>
                    <div className="mil-pentagon"><div></div><div></div><div></div><div></div><div></div></div>
                    <div className="mil-pentagon"><div></div><div></div><div></div><div></div><div></div></div>
                </div>
            </div>
            <div className="mil-animation mil-position-2 mil-scale" data-value-1="4" data-value-2="1" style={{translate: 'none', rotate: 'none', scale: 'none', transform: 'translate(0px, 0px)'}}>
                <div className="mil-dodecahedron"><div className="mil-pentagon"><div></div><div></div><div></div><div></div><div></div></div><div className="mil-pentagon"><div></div><div></div><div></div><div></div><div></div></div><div className="mil-pentagon"><div></div><div></div><div></div><div></div><div></div></div><div className="mil-pentagon"><div></div><div></div><div></div><div></div><div></div></div><div className="mil-pentagon"><div></div><div></div><div></div><div></div><div></div></div><div className="mil-pentagon"><div></div><div></div><div></div><div></div><div></div></div><div className="mil-pentagon"><div></div><div></div><div></div><div></div><div></div></div><div className="mil-pentagon"><div></div><div></div><div></div><div></div><div></div></div><div className="mil-pentagon"><div></div><div></div><div></div><div></div><div></div></div><div className="mil-pentagon"><div></div><div></div><div></div><div></div><div></div></div><div className="mil-pentagon"><div></div><div></div><div></div><div></div><div></div></div><div className="mil-pentagon"><div></div><div></div><div></div><div></div><div></div></div></div>
            </div>
            <div className="mil-animation mil-position-3 mil-scale" data-value-1="1.2" data-value-2=".1" style={{translate: 'none', rotate: 'none', scale: 'none', transform: 'scale(0.2, 0.2)'}}>
                <div className="mil-dodecahedron"><div className="mil-pentagon"><div></div><div></div><div></div><div></div><div></div></div><div className="mil-pentagon"><div></div><div></div><div></div><div></div><div></div></div><div className="mil-pentagon"><div></div><div></div><div></div><div></div><div></div></div><div className="mil-pentagon"><div></div><div></div><div></div><div></div><div></div></div><div className="mil-pentagon"><div></div><div></div><div></div><div></div><div></div></div><div className="mil-pentagon"><div></div><div></div><div></div><div></div><div></div></div><div className="mil-pentagon"><div></div><div></div><div></div><div></div><div></div></div><div className="mil-pentagon"><div></div><div></div><div></div><div></div><div></div></div><div className="mil-pentagon"><div></div><div></div><div></div><div></div><div></div></div><div className="mil-pentagon"><div></div><div></div><div></div><div></div><div></div></div><div className="mil-pentagon"><div></div><div></div><div></div><div></div><div></div></div><div className="mil-pentagon"><div></div><div></div><div></div><div></div><div></div></div></div>
            </div>
        </div>

        <div className="mil-gradient"></div>

        <div className="container">
            <div className="mil-banner-content mil-up">

                {!!title && <h1 className="mil-muted mil-mb-60">{title}</h1>}

                <Link to="/services" className="icons mil-button mil-btn-space">
                    <span>What we do</span>
                    <i className="fa fa-arrow-right icon-black" ></i>
                </Link>
            </div>
        </div>
    </div>
</section>
}
export default Header