import Header from "./Header"
import About from "./About"
import Services from './Services';
import Clients from './Clients';
import Publications from './Publications';
import OurPartners from './OurPartners';

const Home = () => {
    return <div className='home'>
            <Header />
            <About />
            <Services />
            <Clients />
            <Publications />
            <OurPartners /> 
    </div>
}
export default Home