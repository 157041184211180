import { Link } from 'react-router-dom';
import HomeService from '../../services/HomeService';
import { useEffect, useState } from 'react';
import SettingService from '../../services/SettingService';
import ViewModal from './Modal';
import './style.scss'
import { Helmet } from 'react-helmet';

const WebsiteDesign = () => {
    const [data, setData] = useState([])
    const [modal, setModal] = useState(false)
    const [item, setItem] = useState({})
    const [phoneNumber, setPhoneNumber] = useState('')
    const settingService = new SettingService()
    const homeService = new HomeService()

    useEffect(()=>{
        homeService.getDesign().then(res=>{
            if(res?.status === 200){
                setData([...res?.data?.data])
            }
        })
    },[])

    useEffect(()=>{
        settingService.getList().then(res=>{
            if(res?.status === 200){
                setPhoneNumber(res?.data?.data?.whatsapp)
            }
        })
    },[])
    const goToWhatsapp = () =>{
        let whatsappUrl = "https://wa.me/" + phoneNumber
        window.open(whatsappUrl);
    }

    return <>
    <Helmet>
        <meta name="description" content="Cloud Lift Solutions specializes in expert web and app development in Kuwait. Our services deliver cutting-edge, customized technology solutions that drive innovation and success for businesses." />
        <meta name="keywords" content="Expert Web and App Development Kuwait" />
        <title>Expert Web & App Development in Kuwait | Cloud Lift Solutions</title>
    </Helmet>
    <section className="mil-dark-bg">
        <div className="mil-inner-banner">
        <div className="mi-invert-fix">
            <div className="mil-banner-content mil-up">
                <div className="mil-animation mil-position-4 mil-scale" data-value-1="6" data-value-2="1.4" style={{translate: 'none', rotate: 'none', scale: 'none', transform: 'scale(1.2, 1.2)'}}>
                        <div className="mil-dodecahedron"><div className="mil-pentagon"><div></div><div></div><div></div><div></div><div></div></div><div className="mil-pentagon"><div></div><div></div><div></div><div></div><div></div></div><div className="mil-pentagon"><div></div><div></div><div></div><div></div><div></div></div><div className="mil-pentagon"><div></div><div></div><div></div><div></div><div></div></div><div className="mil-pentagon"><div></div><div></div><div></div><div></div><div></div></div><div className="mil-pentagon"><div></div><div></div><div></div><div></div><div></div></div><div className="mil-pentagon"><div></div><div></div><div></div><div></div><div></div></div><div className="mil-pentagon"><div></div><div></div><div></div><div></div><div></div></div><div className="mil-pentagon"><div></div><div></div><div></div><div></div><div></div></div><div className="mil-pentagon"><div></div><div></div><div></div><div></div><div></div></div><div className="mil-pentagon"><div></div><div></div><div></div><div></div><div></div></div><div className="mil-pentagon"><div></div><div></div><div></div><div></div><div></div></div></div>
                    </div>
                <div className="container" style={{flexDirection: "column", alignItems: "baseline"}}>
                    <ul className="mil-breadcrumbs mil-light mil-mb-60">
                        <li><Link to='/'>Homepage</Link></li>
                        <li>Website & Application Development</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
        <div className="website">
            <div className='container'>
                <div className='row'>
                    <div className='mb-5'>
                        <h2>Portfolio</h2>
                        <p>
                            Explore our portfolio of innovative technology solutions, designed to drive success for businesses. Ready to elevate your project? Discover how our expertise can turn your vision into reality. Let's connect and build something remarkable together.
                        </p>
                    </div>
                    {data?.map((res, index)=>{
                        return <div 
                            key={res?.id} 
                            onClick={()=> {
                                setItem(res)
                                setModal(true)
                            }} 
                            className='col-md-6 mb-4 design-image'>
                            <img src={res?.image} alt="design" className='w-100 h-100' />
                            <p>{res?.title}</p>
                    </div>
                    })}
                </div>
                <div className='ready'>
                    <h2>Are You Ready to kickstart your project?</h2>
                    <p>Reach out and let's make it happen ✨. I'm also available for full-time or Part-time opportunities to push the boundaries of design and deliver exceptional work.</p>
                    <button onClick={goToWhatsapp}>Let's Talk</button>
                </div>
            </div>
        </div>

        {(modal && item?.web_design_attachments?.length > 0 ) && <ViewModal modal={modal} setModal={setModal} item={item} />}
    </section>
    </>
}
export default WebsiteDesign;