import { API_BASE_URL_ENV } from "../common/common";
import BaseService from "./BaseService";
import http from './HttpService'

const apiEndpoint = API_BASE_URL_ENV() + "/setting";
const apiPdfsEndpoint = API_BASE_URL_ENV() + "/setting/cloudPdf";

export default class SettingService extends BaseService {
    constructor() {
        super(apiEndpoint);
    }

    getPdfsList() {
        return http.get(apiPdfsEndpoint);
    }
}