import { API_BASE_URL_ENV } from "../common/common";
import http from './HttpService'
import BaseService from "./BaseService";

const apiEndpoint = API_BASE_URL_ENV() + "/marketing";

export default class MarketingService extends BaseService {
    constructor() {
        super(apiEndpoint);
    }
    //Banners
    getBanners() {
        return http.get(`${this.apiEndpoint}/marketingBanner`);
    }

    // Video
    getVideo() {
        return http.get(`${this.apiEndpoint}/marketingVideo`);
    }

    // Services
    getServices() {
        return http.get(`${this.apiEndpoint}/marketingService`);
    }
}