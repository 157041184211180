import { API_BASE_URL_ENV } from "../common/common";
import http from './HttpService'
import BaseService from "./BaseService";

const apiEndpoint = API_BASE_URL_ENV() + "/branding";

export default class BrandingService extends BaseService {
    constructor() {
        super(apiEndpoint);
    }
    //Banners
    getBanners() {
        return http.get(`${this.apiEndpoint}/brandingBanner`);
    }

    // What We Do
    getWWD() {
        return http.get(`${this.apiEndpoint}/whatWeDo`);
    }

    // What We Do
    getServices() {
        return http.get(`${this.apiEndpoint}/brandingService`);
    }

    //Images
    getImages() {
        return http.get(`${this.apiEndpoint}/brandingImages`);
    }
}