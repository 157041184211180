import { Link } from 'react-router-dom';
import './style.scss'
import HomeService from '../../services/HomeService';
import { useEffect, useState } from 'react';

const Clients = () => {
    const [data, setData] = useState([])
    const homeService = new HomeService()

    useEffect(()=>{
        homeService.getClients().then(res=>{
            if(res?.status === 200){
                setData([...res?.data?.data])
            }
        })
    },[])

    return <>
    <section className="mil-dark-bg">
        <div className="mil-inner-banner">
        <div className="mi-invert-fix">
            <div className="mil-banner-content mil-up">
                <div className="mil-animation mil-position-4 mil-scale" data-value-1="6" data-value-2="1.4" style={{translate: 'none', rotate: 'none', scale: 'none', transform: 'scale(1.2, 1.2)'}}>
                        <div className="mil-dodecahedron"><div className="mil-pentagon"><div></div><div></div><div></div><div></div><div></div></div><div className="mil-pentagon"><div></div><div></div><div></div><div></div><div></div></div><div className="mil-pentagon"><div></div><div></div><div></div><div></div><div></div></div><div className="mil-pentagon"><div></div><div></div><div></div><div></div><div></div></div><div className="mil-pentagon"><div></div><div></div><div></div><div></div><div></div></div><div className="mil-pentagon"><div></div><div></div><div></div><div></div><div></div></div><div className="mil-pentagon"><div></div><div></div><div></div><div></div><div></div></div><div className="mil-pentagon"><div></div><div></div><div></div><div></div><div></div></div><div className="mil-pentagon"><div></div><div></div><div></div><div></div><div></div></div><div className="mil-pentagon"><div></div><div></div><div></div><div></div><div></div></div><div className="mil-pentagon"><div></div><div></div><div></div><div></div><div></div></div><div className="mil-pentagon"><div></div><div></div><div></div><div></div><div></div></div></div>
                    </div>
                <div className="container" style={{flexDirection: "column", alignItems: "baseline"}}>
                    <ul className="mil-breadcrumbs mil-light mil-mb-60">
                        <li><Link to='/'>Homepage</Link></li>
                        <li>Clients</li>
                    </ul>
                </div>
            </div>
        </div>
        </div>
    </section>
        <div className="website mt-5">
            <div className='container'>
                <div className='row'>
                    {data?.map(res=>{
                        return <div key={res?.id} className='col-md-3 mb-4'>
                        <div className='card'>
                            <div className='card-img' style={{height: '250px'}}>
                                <img src={res?.image} alt="design" className='w-100 h-100' />
                            </div>
                        </div>
                    </div>
                    })}
                </div>
            </div>
        </div>
    
    </>
}
export default Clients;